import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const GetStartedWithSynkli = () => {
  return (
    <Layout>
      <Seo
        title="Get Started With Synkli"
        description={`Get started with Synkli: scan receipts, track mileage, and generate reports for easy expense tracking and tax management. Simplify your finances today!`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`get-started-with-synkli`} />
    </Layout>
  )
}

export default GetStartedWithSynkli
